import * as React from 'react'
import { graphql } from 'gatsby'
import DefaultLayout from 'layouts/Default'
import Block from 'components/blocks/Block'

const ServiceTemplate = ({ data: { pg } }) => (
	<DefaultLayout {...pg.seo}>
		{pg.blocks?.map((block) => (
			<Block {...block} key={block?._key} />
		))}
	</DefaultLayout>
)

export default ServiceTemplate

export const query = graphql`
	query ServiceTemplate($id: String) {
		pg: sanityServicesPg(id: { eq: $id }) {
			blocks {
				... on SanityBlockGroup {
					_key
					_type
					style
					blocks {
						...statList
					}
				}
				...descriptionColumns
				...featuresWithIcons
				...heroBlock
				...heroGrayed
				...imageGallery
				...projectList
				...servicesList
				...testimonial
				...textWithBg
			}
			seo {
				title
				description
			}
		}
	}
`
